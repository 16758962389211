import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import WaveSVGTop from "../components/WaveSVGTop"
import AltPageBackground from "../components/AltPageBackground"

const ShowcaseItem: React.FC<{
  imageData: FluidObject
  title: string
  description: string
  href: string
}> = ({ imageData, title, description, href }) => {
  return (
    <div className="grid-item-3">
      <div className="showcase-link-container">
        <a
          className="showcase-link"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="image-container">
            <GatsbyImage fluid={imageData} />
          </div>
          <div className="content">
            <h6>{title}</h6>
            <p>{description}</p>
          </div>
        </a>
      </div>
    </div>
  )
}

const ShowcasePage: React.FC = () => {
  const imageData = useStaticQuery(graphql`
    query {
      till: file(relativePath: { eq: "till_showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dzignStudio: file(relativePath: { eq: "dzign_studio_showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      philipsAcademy: file(
        relativePath: { eq: "philips_academy_showcase.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      qcCartCraft: file(relativePath: { eq: "qc_cart_craft_showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quipquotes: file(relativePath: { eq: "quipquotes_showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paFlavorHouse: file(
        relativePath: { eq: "pa_flavor_house_showcase.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const items = [
    {
      imageData: imageData.till.childImageSharp.fluid,
      title: "Till",
      description:
        "Food delivery service which allows customers to order farm-fresh foods and have them delivered to their doorstep",
      href: "https://usetill.com",
    },
    {
      imageData: imageData.dzignStudio.childImageSharp.fluid,
      title: "Dzign Studio",
      description:
        "Online boutique specializing in custom poured acrylic, industrial frames, and woodwork",
      href: "https://shopdzignstudio.com",
    },
    {
      imageData: imageData.philipsAcademy.childImageSharp.fluid,
      title: "Philips Academy",
      description:
        "Private non-profit school for students in middle and high school with learning disabilities",
      href: "https://philipsacademync.org",
    },
    {
      imageData: imageData.qcCartCraft.childImageSharp.fluid,
      title: "QC Cart Craft",
      description:
        "Custom cart builds and street-legal vehicle conversions for the greater Charlotte area",
      href: "https://qccartcraft.com",
    },
    {
      imageData: imageData.quipquotes.childImageSharp.fluid,
      title: "QuipQuotes",
      description:
        "Online marketplace bringing equipment buyers, dealers, and manufacturers together",
      href: "https://quipquotes.com",
    },
    {
      imageData: imageData.paFlavorHouse.childImageSharp.fluid,
      title: "Flavor House",
      description:
        "Philips Academy's online store for selling coffee flavored by its very own students",
      href: "https://coffee.philipsacademync.org",
    },
  ]
  return (
    <Layout
      title="Project Showcase"
      description="Our home is in Charlotte, but our clients are all over the country. Check out some of our latest projects!"
    >
      <Navbar />
      <div className="body-container">
        <div className="alt-page-header">
          <AltPageBackground />
          <h1>project showcase</h1>
          <div className="wave-svg-container">
            <WaveSVGTop />
          </div>
        </div>
        <div className="layout-container">
          <div className="alt-page-container">
            <h6 className="text-center">
              Our home is in Charlotte, but our clients are all over the
              country. Check out some of our latest projects!
            </h6>
            <div className="grid-container add-width-2 margin-top-4">
              {items.map(item => {
                return <ShowcaseItem {...item} key={item.title} />
              })}
            </div>
            <div className="text-center margin-top-4">
              <Link
                to="/#contact"
                className="btn primary-btn full-width-mobile"
              >
                CONTACT US TODAY
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ShowcasePage
